import { Model } from 'pinia-orm';
// import { Bool, Str, Uid, Attr } from 'pinia-orm/dist/decorators';
import { useVaultStore } from '@/store/vault';

export class Page extends Model {
  // entity is a required property for all models.
  static entity = 'pages';
  static primaryKey = 'id';

  static fields() {
    return {
      id: this.uid(),
      type: this.string('page').notNullable(),
      name: this.string(''),
      value: this.string(''),
      editable: this.boolean(false).notNullable(),
      createdAt: this.string(''),
      updatedAt: this.string(''),
    };
  }

  static creating(model: Page) {
    if (!model.createdAt) model.createdAt = new Date().toISOString();
  }

  static updating(model: Page) {
    model.updatedAt = new Date().toISOString();
  }

  static created() {
    const vaultStore = useVaultStore();
    vaultStore.persist();
  }
  static updated() {
    const vaultStore = useVaultStore();
    vaultStore.persist();
  }
  static deleted() {
    const vaultStore = useVaultStore();
    vaultStore.persist();
  }
}
