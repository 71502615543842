import { useSnackbarStore } from '~/store/snackbar';

interface Action {
  actionName?: string;
  run?: () => void;
}

export const createVisibleError = (
  name: string,
  message: string,
  store: boolean = false,
  _action: Action = {}
) => {
  logger().debug('composables/useVisibleError.ts', '', name, message);
  const snackbarStore = useSnackbarStore();

  snackbarStore.showSnackbar({
    message,
    type: 'error',
  });

  if (store === true) {
    throw createError({
      name,
      message,
      data: {
        log: true,
        store: true,
        show: true,
      },
    });
  }
};
