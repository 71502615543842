import {
  applyActionCode,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { Bytes, doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuthStore } from '@/store/auth';
import { useVaultStore } from '../store/vault';
import type { User } from 'firebase/auth';

export const AUTH_ERROR_CODES_MAP: { [key: string]: string } = {
  //* These errors are save but need feedback
  'auth/admin-restricted-operation': '',
  'auth/email-change-needs-verification': '',
  'auth/email-already-in-use':
    'Dit email adres is al in gebruik, gebruik je wachtwoord om in te loggen.',
  'auth/invalid-verification-code': '',
  'auth/invalid-email': 'Er bestaat nog geen account met dit email adres',
  'auth/wrong-password': 'Het wachtwoord klopt niet. Foutje?',
  'auth/missing-verification-code': '',
  'auth/unverified-email':
    'Dit email adres moet eerst nog geverifieerd worden. Er is een bericht gestuurd naar je email.',
  'auth/user-cancelled': '',
  'auth/user-not-found':
    'Dit email adres is nog niet in gebruik, maak eerst een account aan.',
  'auth/user-signed-out': 'Je bent uitgelogd.',
  'auth/weak-password': 'Zwak! Dit wachtwoord is niet sterk genoeg.',
  'auth/network-request-failed':
    'Er lijkt geen verbinding met het internet te zijn',
  'permission-denied': 'Je bent uitgelogd.',
  //* These errors are unsafe and need logging
  // 'auth/internal-error': '',
  // 'auth/argument-error': '',
  // 'auth/quota-exceeded': '',
  // 'auth/too-many-requests': '',
  // 'auth/user-disabled': '',
};

/**
 * Create user specific salt and iv to remote store. Should only be run once
 * for every account.
 *
 * @param uid User id.
 */
export function createEncryption(uid: string) {
  const { $firestore } = useNuxtApp();
  const { salt, iv } = generateSaltAndIv();

  return setDoc(doc($firestore, `keys/${uid}`), {
    salt: Bytes.fromUint8Array(salt),
    iv: Bytes.fromUint8Array(iv),
  });
}

/**
 * Create a new account.
 *
 * @param email
 * @param password
 */
export function createAccount(email: string, password: string) {
  const { $auth } = useNuxtApp();

  return createUserWithEmailAndPassword($auth, email, password).then(
    ({ user }) => {
      return sendEmailVerification(user).then(() => {
        return createEncryption(user.uid);
      });
    }
  );
}

/**
 * Verify that an account exists.
 *
 * @param code oobCode
 */
export function verifyAccount(code: string) {
  const { $auth } = useNuxtApp();

  return applyActionCode($auth, code);
}

/**
 * Log into account.
 *
 * @param email
 * @param password
 */
export async function logIn(email: string, password: string) {
  const { $auth } = useNuxtApp();
  const vaultStore = useVaultStore();
  const authStore = useAuthStore();

  const { user }: { user: User } = await signInWithEmailAndPassword(
    $auth,
    email,
    password
  ).catch((error) => {
    logger().error(
      'utils/auth.ts',
      'logIn.signInWithEmailAndPassword()',
      error.message,
      AUTH_ERROR_CODES_MAP[error.code]
    );
    if (AUTH_ERROR_CODES_MAP[error.code]) {
      throw createVisibleError(error.name, AUTH_ERROR_CODES_MAP[error.code]);
    } else {
      throw createVisibleError(
        error.name,
        `Kon niet inloggen: ${error.message}`,
        true
      );
    }
  });
  const { claims } = await user.getIdTokenResult();

  authStore.setUser(user, claims);
  // Load encrypted pages
  await vaultStore.decryptVault(email, password).catch((error) => {
    logger().error(
      'utils/auth.ts',
      'logIn.decryptVault()',
      'Vault not decrypted, delete vault and try again',
      error
    );
  });

  return user;
}

/**
 * Get salt and iv from remote store to decrypt a local store with.
 */
export function fetchEncryptionKeys() {
  const { $firestore } = useNuxtApp();
  const authStore = useAuthStore();

  if (authStore.user == null) {
    throw new Error(`Could't get salt and iv, user ${authStore.user}`);
  }

  return getDoc(doc($firestore, 'keys', authStore.user.uid)).then((doc) => {
    const { salt, iv }: { salt?: Bytes; iv?: Bytes } = doc.data()
      ? (doc.data() as { salt: Bytes; iv: Bytes })
      : {};

    if (!salt || !iv) {
      throw new Error(`Missing salt ('${salt}') or iv ('${iv}').`);
    }

    return {
      salt: salt.toUint8Array(),
      iv: iv.toUint8Array(),
    };
  });
}

/**
 * Log out of account.
 */
export async function logOut() {
  const { $auth } = useNuxtApp();
  const authStore = useAuthStore();
  const vaultStore = useVaultStore();

  vaultStore.encryptVault();
  authStore.clear();

  return signOut($auth);
}
