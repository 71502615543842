import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { Page } from './models/Page';

type Lock = (encrypted: string) => Promise<string>;

export enum LockState {
  Unlocked,
  Locked,
}

const VAULT_ID = 'm1-vault';

export const useVaultStore = defineStore('vault', {
  state: () => ({
    id: null as string | null,
    lock: null as Lock | null,
    state: LockState.Locked,
    vault: null as string | null,
  }),

  getters: {
    getVault: (state) => {
      if (!state.id) {
        throw Error(
          'Vault id missing, be sure tor set the id before storing anything.'
        );
      }
      // @ts-expect-error: invalid typing
      state.vault = useLocalStorage(state.id, '');

      return state.vault as string;
    },
  },

  actions: {
    setVaultId(email: string) {
      this.id = `${VAULT_ID}-${btoa(email)}`;
    },

    async createLock(password: string) {
      const { salt, iv } = await fetchEncryptionKeys();

      this.lock = await createEncryptor(password, salt, iv);
    },

    async decryptVault(email: string, password: string) {
      if (this.state === LockState.Unlocked) return;
      this.setVaultId(email);

      const { salt, iv } = await fetchEncryptionKeys();
      const { decrypted, encrypt } = await decrypt(
        this.getVault,
        password,
        salt,
        iv
      );

      this.lock = encrypt;

      const pages: Page[] = JSON.parse(decrypted);

      // decrpted may return "[{}]" which is missing an id then break pinia ORM
      if (pages && pages[0] && pages[0].id) {
        pages.forEach((page) => {
          useRepo(Page).save(page);
        });
      }

      this.state = LockState.Unlocked;
    },

    async persist() {
      if (!this.vault) this.getVault;
      if (typeof this.lock !== 'function') {
        return logger().error(
          'vault.ts',
          'persist()',
          "Vault not unlocked, couldn't store data"
        );
      }

      this.vault = await this.lock(JSON.stringify(useRepo(Page).all()));
    },

    encryptVault() {
      // Clear models in store
      useRepo(Page).flush();

      this.lock = null;
      this.state = LockState.Locked;
    },
  },
});
