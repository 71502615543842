// import type { KeyUsage } from 'node'

async function createAesKey(
  password: string,
  salt: Uint8Array,
  // eslint-disable-next-line no-undef
  options: KeyUsage[] = ['encrypt', 'decrypt']
) {
  const passwordKey = await window.crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(password),
    'PBKDF2',
    false,
    ['deriveKey']
  );

  return window.crypto.subtle.deriveKey(
    { name: 'PBKDF2', salt, iterations: 250000, hash: { name: 'SHA-256' } },
    passwordKey,
    { name: 'AES-GCM', length: 256 },
    false,
    options
  );
}

async function encryptWithKey(aesKey: CryptoKey, iv: Uint8Array, text: string) {
  const encryptedBuffer = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    aesKey,
    new TextEncoder().encode(text)
  );

  const cipherArray = Array.from(new Uint8Array(encryptedBuffer)); // cipher as byte array
  const cipherString = cipherArray
    .map((byte) => String.fromCharCode(byte))
    .join(''); // cipher as string
  return btoa(cipherString); // Encode cipher string as base64
}

/**
 * Create salt and initialization vectors for encryption
 */
export const generateSaltAndIv = () => ({
  salt: window.crypto.getRandomValues(new Uint8Array(32)),
  iv: window.crypto.getRandomValues(new Uint8Array(12)),
});

export async function createEncryptor(
  password: string,
  salt: Uint8Array,
  iv: Uint8Array
) {
  const aesKey = await createAesKey(password, salt, ['encrypt']);

  return function encrypt(text: string) {
    return encryptWithKey(aesKey, iv, text);
  };
}

export async function decrypt(
  cipherStringBase64: string,
  password: string,
  salt: Uint8Array,
  iv: Uint8Array
) {
  const encryptKey = await createAesKey(password, salt, ['encrypt']);
  const encrypt = (text: string) => {
    return encryptWithKey(encryptKey, iv, text);
  };

  if (cipherStringBase64 == null || cipherStringBase64 === '') {
    return {
      decrypted: '[]',
      encrypt,
    };
  }

  const decryptKey = await createAesKey(password, salt, ['decrypt']);
  const cipherString = atob(cipherStringBase64); // decode base64 cipher string
  const cipherArray = new Uint8Array(
    cipherString.match(/[\s\S]/g)!.map((ch) => ch.charCodeAt(0))
  );
  const decrypted = await window.crypto.subtle.decrypt(
    { name: 'AES-GCM', iv },
    decryptKey,
    cipherArray
  );

  return {
    decrypted: new TextDecoder().decode(decrypted),
    encrypt,
  };
}
